h1,
h2,
h3,
h4,
h5 {
  color: var(--c-mauve-miller);
}

p {
  color: var(--c-beige-louche);
  line-height: 1.5;
}
