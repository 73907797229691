@use "~sass-mq" as mq;

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: center;
  background: var(--c-vert);
  color: var(--c-beige-louche);
  padding: 16px;

  @include mq.mq($from: tablet) {
    height: 110px;
    flex-direction: row;
    align-items: flex-end;
  }

  &__logo {
    display: block;
    width: 250px;
    flex-direction: row;
    align-items: flex-end;

    @include mq.mq($from: tablet) {
      position: absolute;
      left: 32px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__nav {
    display: flex;
    gap: 16px;

    &__item {
      color: var(--color-beige-louche);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
