.game {
  &__wrapper {
    width: 100%;
    position: relative;
    padding-bottom: calc(56.25% + 50px); /* 16:9 */
    height: 0;
  }

  &__embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
