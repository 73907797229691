@use "../vendors/sass-mq" as mq;

main {
  width: 100%;
  height: 100vh;
  gap: 16px;
  flex: 1;
  padding: 0 16px;
  margin: 0 auto;

  @include mq.mq($from: tablet) {
    max-width: 768px;
  }

  @include mq.mq($from: desktop) {
    max-width: 992px;
  }
}
