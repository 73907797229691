@use "~sass-mq" as mq;

.footer {
  padding: 32px 16px;
  text-align: center;
  font-size: 14px;

  &__credits {
    font-size: 14px;
  }
}
